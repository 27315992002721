<template>
  <div class="knowledge-search-page">
    <van-search
      v-model="params.search_content"
      show-action
      placeholder="搜索"
      @search="onSearch"
      @cancel="onCancel"
    />
    <div class="search-content">
      <!-- <sendListItem
        v-for="(item, index) in list"
        :sendItem="item"
        pageType="knowledge"
        :limitNum="5"
        :mediaList="item.file_arr"
        :customer_userid="customer_userid"
        :member_userid="member_userid"
        :index="index"
        :keyArr="keyArr"
        backgroundColor="#fff"
        cutColor="#f5f5f5"
      ></sendListItem> -->
      <van-list
        v-if="list.length || refreshing"
        v-model:loading="loading"
        v-model:error="error"
        :finished="finished"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
        :finished-text="list.length == 0 ? '暂无数据' : '没有更多了'"
        @load="onload"
      >
        <sendListItem
          v-for="(item, index) in list"
          :sendItem="item"
          pageType="speech"
          :customer_userid="customer_userid"
          :member_userid="member_userid"
          :chat_id="chat_id"
          :index="index"
          :key="index"
          :keyArr="keyArr"
          cutColor="#f5f5f5"
        ></sendListItem>
      </van-list>
      <div v-if="list.length === 0 && searched" class="search-nodata">
        暂无搜索结果
      </div>
    </div>
  </div>
</template>

<script>
import { Search, List } from 'vant'
import sendListItem from '../../../../components/quickSend/sendListItem.vue'
import { getSpeechList } from '../../../../service/quickSend.service'
import {
  getCurExternalContact,
  getCurExternalChat
} from '../../../../utils/nwechat.js'
import { personalSpeech } from '../tabPage/test'
export default {
  components: {
    [Search.name]: Search,
    [List.name]: List,
    sendListItem: sendListItem
  },
  data() {
    return {
      list: [],
      customer_userid: '',
      chat_id: '',
      member_userid: '',
      loading: false,
      error: false,
      finished: false,
      params: {
        page_num: 1,
        page_size: 20,
        search_content: ''
      },
      keyArr: [],
      searched: false,
      requestStatus: null,
      pollingIndex: 0
    }
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.requestStatus)
  },
  created() {
    // this.onSearch()
    this.customer_userid = this.$route.query.customer_userid || ''
    this.chat_id = this.$route.query.chat_id || ''
    console.log('搜索页id', this.customer_userid, this.chat_id)
    this.init()
  },
  methods: {
    init() {
      // 轮询请求客户id
      this.requestStatus = setInterval(() => {
        if (this.customer_userid || this.chat_id || this.pollingIndex > 1) {
          console.log('搜索页轮询次数-最终-count', this.pollingIndex)
          clearInterval(this.requestStatus)
          return
        }
        // console.log('现在是第', this.pollingIndex)
        console.log('搜索页轮询次数-count', this.pollingIndex)
        this.pollingIndex++
        getCurExternalContact()
          .then((res) => {
            this.customer_userid = res
          })
          .catch(() => {
            // this.customer_userid = ''
          })
        getCurExternalChat()
          .then((res) => {
            // console.log('resres群', res)
            this.chat_id = res
          })
          .catch(() => {
            // this.chat_id = ''
          })
      }, 50)
    },
    // 搜索
    onSearch() {
      // this.list = personalSpeech.data.list || []
      // console.log(this.list)
      this.keyArr[0] = this.params.search_content
      this.list = []
      this.params.page_num = 1

      if (!this.params.search_content) {
        return
      }
      this.searched = true
      this.onload()

      // getSpeechList(this.params)
      //   .then((res) => {
      //     console.log(res)
      //     if (res.code == 200) {
      //       this.list = res.data || []
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e)
      //   })
    },

    // 加载列表
    async onload() {
      if (this.list.length > 0) {
        this.params.page_num += 1
      }
      try {
        this.loading = true
        const { data, code } = await getSpeechList(this.params)

        if (code !== 200) {
          this.error = true
          this.count = 0
          throw code
        }

        this.count = data.total_num || 0
        this.list = this.list.concat(data.list)
        this.loading = false

        // 翻到最后一页
        if (
          this.list.length < this.params.page_size ||
          this.count === this.list.length
        ) {
          this.finished = true
        }
      } catch (error) {
        this.error = true
        this.finished = true
        console.log(error)
      } finally {
        this.loading = false
        return Promise.resolve(true)
      }
    },
    onCancel() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.knowledge-search-page {
  .search-content {
    box-sizing: border-box;
    // width: 295px;
    background-color: #f5f5f5;
    height: calc(100vh - 55px);
    padding: 20px 15px;
    overflow-y: scroll;
    position: relative;
  }

  .search-nodata {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    color: #909399;
    text-align: center;
    font-size: 14px;
  }
}
</style>
